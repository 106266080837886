import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/cheesing.jpg'

class Header extends React.Component {
  render() {
    return (
      <header id="header">
        <div className="inner">
          <a href="#" className="image avatar">
            <img src={avatar} alt="" />
          </a>
          <h1>
            My name is <strong>Qiaowei Tang. </strong>
            <br />
            It's easier to just call me Joel.
            <br />
          </h1>
          <h1>
            <br />
            I am currently a front-end Angular developer.
            <br />
          </h1>
        </div>
        <Footer />
      </header>
    )
  }
}

export default Header
